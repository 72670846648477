



































import { Component, Prop, Vue } from 'vue-property-decorator';

import MButton from '@/shared/components/MButton.vue';

import { PathProduct } from '@/types/path';

@Component({
  components: { MButton },
})
export default class PathsTableCourses extends Vue {
  @Prop({ required: true, default: [] }) products!: PathProduct[];
  @Prop({ required: true, default: 0 }) totalProducts!: number;
  @Prop({ required: true, default: '' }) loading!: string;
  @Prop({ required: true }) canEdit: boolean;

  selectedToRemove = {} as PathProduct;
  fields: any[] = [];
  sortBy: string = 'name';

  created() {
    this.fields = [
      {
        key: 'name',
        label: this.$t('sparkmembers.contents.views.paths.table.product'),
        sortable: true,
      },
      {
        key: 'kind',
        label: this.$t('sparkmembers.contents.views.paths.table.type'),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t('sparkmembers.contents.views.paths.table.actions'),
        thClass: 'tlw-text-center',
        tdClass: 'actions',
      },
    ];
  }

  removeProduct(product: PathProduct) {
    this.selectedToRemove = product;
    this.$emit('removeProduct', product);
  }
}
